<template>
  <div class="coupe-de-france-du-potager max-w-2xl">
    <div class="w-100 text-center font-large-1">
      <img alt="logo coupe du monde du potager" class="height-100" src="@/assets/images/cfp/logo_coupe.png">
    </div>

    <div v-if="!this.$store.state.account.user" class="w-100 d-flex justify-content-center mt-1">
      <b-card bg-variant="secondary-light" class="mt-1 max-w-md text-center">
        <span class="font-bold text-danger mb-1">Attention !</span> Vous n'êtes pas connecté. Pour utiliser cet outil il
        vous faut un compte Hortilio.
        <br>
        <strong>Merci d’utiliser l’adresse mail du référent de votre équipe.</strong>
        <br>
        <b-button
            variant="secondary"
            @click="$router.push({path: '/pages/register?creation_code=cdf_potager'})">
          Création de compte Hortilio
        </b-button>
      </b-card>
    </div>

    <div v-if="establishmentType && this.$store.state.account.user">
      <div
          v-for="(establishmentType, index) of activeEstablishmentButtons"
          :key="index"
          class="w-100 d-flex justify-content-center mt-1">
        <b-button
            :variant="index % 2 === 0 ? 'primary' : 'outline-primary'"
            size="lg"
            @click="$router.push({name: 'coupe-de-france-du-potager-evaluation', params: {establishmentType: establishmentType.routeName}})">
          Ouvrir l'évaluation {{ establishmentType.name }}
        </b-button>
      </div>

      <div
          v-if="establishmentType && !this.$store.state.account.user.is_superuser"
          class="w-100 d-flex justify-content-center mt-50">
        <b-button
            variant="flat-secondary"
            @click="changeTypePrompt = true">
          Mon équipe ne fait pas partie des {{ establishmentType.name }} : Changer ma catégorie de participation à la
          Coupe de France du potager.
        </b-button>
      </div>

      <div
          v-if="user2021Participant"
          class="w-100 d-flex justify-content-center mt-2">
        <b-button
            variant="secondary"
            @click="$router.push({name: 'coupe-de-france-du-potager-evaluation-2021'})">
          <feather-icon
              class="mr-50"
              icon="PlayIcon"
              size="16"/>
          <span class="align-middle"> Voir mes résultats de l'année dernière </span>
        </b-button>
      </div>

      <jaya-divider color="transparent"/>

      <CFPIntro v-if="establishmentType" :establishmentType="establishmentType"/>

      <jaya-divider color="transparent"/>

      <b-card class="max-w-lg" title="Besoin d'aide ?">
        <div class="d-flex align-items-center">
          <img
              alt="Icone de feuille"
              class="mx-1"
              src="@/assets/images/icons/other/question_bubbles.svg"
              style="height: 35px;min-width:40px;">
          <p class="p-0 m-0">
            Une question ou un commentaire sur l’évaluation et ses questions ? Écrivez à l’adresse
            <br>
            <a class="underline" href="mailto:coupedefrancedupotager@landestini.org">
              coupedefrancedupotager&#64;landestini&#46;org
            </a>
          </p>
        </div>
        <div class="d-flex align-items-center pt-50">
          <img
              alt="Icone de feedback negatif"
              class="mx-1"
              src="@/assets/images/icons/other/feedback_negative.svg"
              style="height: 35px;min-width:40px;">
          <p class="p-0 m-0">
            Un problème technique dans le formulaire d’évaluation ? Ecrivez à
            <br>
            <a class="underline" href="mailto:contact@hortilio.fr">
              contact&#64;hortilio&#46;fr
            </a>
          </p>
        </div>
      </b-card>

      <b-modal
          v-model="changeTypePrompt"
          :ok-disabled="!editingEstablishmentType"
          cancel-title="Annuler"
          centered
          ok-title="Valider ce changement"
          title="Changer de catégorie"
          @cancel="changeTypePrompt = false"
          @ok="changeEstablishmentType">
        <b-alert class="text-center" variant="danger">
          Etes vous certain de ne pas faire partie de la catégorie {{ establishmentType.name }} ?
          <br>
          Changer de catégorie implique de remplir à nouveau le questionnaire d'évaluation.
        </b-alert>

        <b-form-select
            v-model="editingEstablishmentType"
            :options="establishmentTypes"
            label="Nouvelle catégorie"/>
      </b-modal>
    </div>
  </div>
</template>

<script>
import CFPIntro from '@/components/cfp/CFPIntro.vue'
import {BAlert, BButton, BCard, BFormSelect, BModal} from "bootstrap-vue";

export default {
  name: 'coupe-de-france-du-potager',
  components: {
    CFPIntro,
    BButton,
    BCard,
    BModal,
    BAlert,
    BFormSelect,
  },
  data() {
    return {
      quiz: null,
      allQuizzes: null,
      establishmentType: null,
      activeEstablishmentButtons: [],
      user2021Participant: false,
      changeTypePrompt: false,
      editingEstablishmentType: null
    }
  },
  mounted() {
    if (this.$store.state.account.user) {
      //Get User establishment Type
      let establishmentTypeType = "SC"
      if (this.$store.state.account.user.user_creation_origin && this.$store.state.account.user.user_creation_origin.includes("cdf_potager")) {
        let creationType = this.$store.state.account.user.user_creation_origin.slice(-2)
        if (creationType === "SC" ||
            creationType === "YS" ||
            creationType === "ST" ||
            creationType === "SP" ||
            creationType === "CO" ||
            creationType === "AS") {
          establishmentTypeType = creationType
        }
      }
      if (this.$store.state.cfp.establishmentTypes.some(type => type.type === establishmentTypeType)) {
        this.establishmentType = this.$store.state.cfp.establishmentTypes.find(type => type.type === establishmentTypeType)
      } else {
        this.establishmentType = this.$store.state.cfp.establishmentTypes.find(type => type.type === "SC")
      }

      this.$loading(true)
      this.$store.dispatch("cfp/getQuiz")
          .then(quizzesResponse => {
            this.allQuizzes = quizzesResponse.data
            //set active button if quiz
            this.$store.dispatch("cfp/getQuizAnswerer")
                .then(response => {
                  if (response.data.length > 0 && response.data[0].updated_at) {
                    let lastQuiz = response.data.reduce((max, quizAnswerer) => max.updated_at > quizAnswerer.updated_at ? max : quizAnswerer)
                    let lastQuizType = this.allQuizzes.find(quiz => quiz.id === lastQuiz.quiz).type
                    this.establishmentType = this.$store.state.cfp.establishmentTypes.find(establishment => establishment.type === lastQuizType)
                  }
                  this.setActiveButtons()
                  this.$loading(false)
                })
                .catch(() => {
                  this.$notify({
                    time: 4000,
                    title: "Erreur d'attribution des réponses",
                    text: "Veuillez réessayer ulterieurement",
                    color: "danger"
                  })
                  this.setActiveButtons()
                  this.$loading(false)
                })
          })
          .catch(() => {
            this.$notify({
              time: 4000,
              title: "Erreur au téléchargement des évaluations",
              text: "Veuillez réessayer ulterieurement",
              color: "danger"
            })
          })

      // Sets user2021Participant true if last year participant
      this.$store.dispatch("feedback/getQuizAnswers", {type: "Coupe de france du potager"})
          .then(response => {
            if (response.data.length > 0 && response.data[0].answers_given) {
              this.user2021Participant = response.data[0].answers_given.length > 0
            }
          })
    }
  },
  computed: {
    establishmentTypes() {
      let res = []
      for (const establishment of this.$store.state.cfp.establishmentTypes) {
        if (this.establishmentType && establishment.id !== this.establishmentType.id) {
          res.push({
            value: establishment.type,
            text: establishment.name
          })
        }
      }
      return res
    }
  },
  methods: {
    setActiveButtons() {
      //set correct quiz ids with mutation in store
      this.$store.commit("cfp/SET_CORRECT_QUIZ_INFOS", this.allQuizzes)

      this.activeEstablishmentButtons = []
      for (const type of this.$store.state.cfp.establishmentTypes) {
        if (this.$store.state.account.user.is_superuser || type.id === this.establishmentType.id) {
          this.activeEstablishmentButtons.push(type)
        }
      }
    },
    changeEstablishmentType() {
      // todoCFP: change type
      if (!this.editingEstablishmentType) {
        this.$notify({
          time: 4000,
          color: "danger",
          title: "Erreur",
          text: "Aucune catégorie séléctionnée"
        })
      } else {
        this.establishmentType = this.$store.state.cfp.establishmentTypes.find(type => type.type === this.editingEstablishmentType)
        this.editingEstablishmentType = null
        this.setActiveButtons()
        this.$notify({
          time: 4000,
          color: "primary",
          title: "Catégorie Modifié",
          text: "Catégorie modifié en " + this.establishmentType.name
        })
      }
    }
  }
}
</script>
