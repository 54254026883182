<template>
  <b-card class="coupe-de-france-du-potager-intro text-justify">
    <p>
      Dites-nous tout sur votre potager ! Bienvenue sur l’outil d’auto-évaluation de la <strong>Coupe de France du
      potager</strong> !
    </p>

    <p>
      Afin de rendre la démarche la plus collaborative possible, <strong>l’évaluation de vos potagers se déroule en
      deux
      temps.</strong> Tout d’abord, vous allez procéder à une auto-évaluation, grâce à cette plateforme de notre
      partenaire,
      Hortilio. Ensuite, le jury (tous les membres sont présentés sur le site
      landestini.org/coupe-de-france-du-potager/) examinera les meilleurs dossiers de chaque catégorie (5 à 20
      dossiers en fonction du nombre d’équipes dans la catégorie) afin d’élire les équipes championnes et lauréates de
      la Coupe de France du potager !
    </p>

    <p>
      <strong>C’est très simple : vous aurez une note globale, et une note par critère. Sera élue une équipe
        championne avec
        la meilleure note et évaluation globales, et une équipe lauréate pour chaque critère.</strong> Ainsi, dans la
      catégorie
      Associations sportives et clubs de sport seront élues 8 équipes lauréates et une équipe championne (et 72
      équipes lauréates et 8 équipes championnes dans toute la Coupe de France du potager).
    </p>

    <p>
      Important : ce formulaire d’auto-évaluation contient de nombreuses questions, et peut représenter un travail
      considérable (3 à 8 heures de travail selon notre sondage l’an passé, en fonction de votre implication et en
      incluant les photos). Nous tenons à rendre ce formulaire le plus intéressant et pédagogique possible. <strong>Ainsi,
      nous encourageons les équipes à remplir ce formulaire de manière collective.</strong> Tout au long du concours,
      nous vous
      fournissons des fiches qui correspondent aux différentes parties du formulaire d’auto-évaluation, aidant à les
      comprendre et les remplir. <strong>Ces fiches sont pour vous si vous souhaitez les utiliser, et ne sont bien sûr
      pas
      obligatoires.</strong> Nous vous enverrons ces fiches par mail à partir de février 2022.
    </p>

    <p>
      Vous pouvez remplir ce formulaire en plusieurs fois, en sauvegardant vos réponses au fur et à mesure. <strong>Si
      vous
      n’avez pas beaucoup de temps à consacrer à ce formulaire, vous n’êtes pas obligé de répondre à toutes les
      questions ! Vous pouvez répondre à celles que vous souhaitez, et simplement lire les autres questions afin
      d’apprendre sur le potager avec votre équipe.</strong> Vous pouvez aussi simplement remplir le formulaire en
      répondant aux réponses à texte libre. Certes, vous n’aurez pas beaucoup de points (patates), mais cela vous
      permet tout de même de soumettre votre formulaire et de participer jusqu’au bout ! <strong>Le formulaire est à
      soumettre
      avant le
      {{ this.establishmentType.end_date.toLocaleDateString('fr-FR', {day: 'numeric', year: 'numeric', month: 'long'}) }} à 23h59.</strong>
    </p>

    <p>
      En faisant et soumettant une courte vidéo sur votre potager, vous pouvez aussi, quoi qu’il arrive, participer au
      concours Coup de coeur du public ! <strong>Nous vous demandons ainsi d’ici le {{ this.establishmentType.end_date.toLocaleDateString('fr-FR', {day: 'numeric', year: 'numeric', month: 'long'}) }} à 23h59 de préparer
      une vidéo
      de deux à cinq minutes, qui présente votre potager et votre démarche globale.</strong> Cette vidéo n’est pas à
      soumettre
      via cette plateforme. Nous vous donnerons des consignes plus précises à ce sujet ultérieurement.
    </p>

    <p>
      Enfin, n’oubliez pas que <strong>tout au long de la Coupe de France du potager, nous animons des séances de
      formation
      questions-réponses en ligne en direct,</strong> pour vous aider dans toute votre démarche sur la mise en place
      et
      l’entretien du potager ! Il s’agit d’un concours, mais aussi d’un dispositif d’accompagnement et d’entraide !
    </p>

    <p>
      Vous êtes tous gagnants en participant à la Coupe de France du potager, et avoir le meilleur score et être
      lauréat ou champion n’est pas le seul objectif.
    </p>

    <p>
      Pour utiliser cette plateforme d’évaluation, il est nécessaire de créer un compte à partir de votre adresse
      mail. <strong>Important : merci d’utiliser l’adresse mail du référent de votre équipe, transmise lors de
      l’inscription.</strong>
      Ceci nous permettra d’identifier votre équipe. Pas d'inquiétude : vos adresses mails ne seront pas utilisées
      dans un but commercial par notre partenaire Hortilio*.
    </p>

    <b-card bg-variant="primary" class="mt-2 max-w-xl text-white text-justify">
      *<strong>Hortilio</strong> est une plateforme numérique au service du jardin, à dimension pédagogique. Son
      objectif est de favoriser la biodiversité en diffusant auprès de tous, néophyte ou jardinier expérimenté, du
      jardin d'agrément au potager productif, les bonnes pratiques de jardinage.
    </b-card>
  </b-card>
</template>


<script>
import {BCard} from "bootstrap-vue";

export default {
  components: {
    BCard,
  },
  props: {
    establishmentType: {
      type: Object,
      required: true,
    },
  }
}
</script>
